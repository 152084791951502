:root {
    /*New palette*/
    --white: #ffffff;
    --yellow: #ffc20e;
    --soft-red: #ee8373;
    --red: #f25656; /*creato perché manca l'animazione dei tasti rossi*/
    --green: #5abeba;
    --hover-green: #3dada8;
    --active-green: #3b9b97;
    --green-8: #F2FAF9;
    --green-16: #E5F5F4;
    --dark-blue: #344d66;
    --blue-90: #485F75;
    --blue-80: #5D7185;
    --blue-70: #718294;
    --blue-60: #8594A3;
    --blue-50: #99A6B2;
    --blue-40: #AEB8C2;
    --blue-30: #C2CAD1;
    --blue-20: #D6DBE0;
    --blue-10: #ebedf0;
    --blue-5: #F5F6F7;
    --black: #0A0F14;
    --black-96: #14181d;
    --black-92: #1d2226;
    --black-88: #272C30;
    --black-84: #313539;
    --black-80: #3b3f43;
}

:root .dark {
    color-scheme: dark;
}

.cont {
    min-height: 100vh;
    min-height: 100dvh;
}

.row {
    --bs-gutter-x: unset !important;
}

.col-container {
    padding: 0px !important;
    background-color: var(--blue-5);
    max-height: 100%;
}

.dark .col-container {
    background-color: var(--black) !important;
}

.col-container.phone {
    height: calc(100vh - 82px) !important;
    height: calc(100dvh - 82px) !important;
}

.center {
    text-align: center;
    justify-content: center;
}

/*Cookies*/
.iubenda-tp-btn[data-tp-float][data-tp-anchored] {
    margin: 0 0px !important;
}

body:has(.phone) .iubenda-tp-btn[data-tp-float][data-tp-anchored] {
    rotate: -90deg;
    right: 0 !important;
    left: unset !important;
    bottom: 50vh !important;
    bottom: 50dvh !important;
    margin: 0 !important;
}

/*Theme*/
/*Button style light*/
/*Primary button*/
.primary-button {
    height: 48px;
    padding: 17px 48px;
    border-radius: 8px !important;
    background-color: var(--green) !important;
    border: 1px solid var(--green) !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5 !important;
    letter-spacing: 1px;
    text-align: center !important;
    color: var(--white) !important;
    width: 100%;
}

.primary-button:hover {
    background-color: var(--hover-green) !important;
    border: 1px solid var(--hover-green) !important;
    box-shadow: 0 0 8px 0 rgba(52, 77, 102, 0.08);
}

.primary-button:active {
    background-color: var(--active-green) !important;
    border: 1px solid var(--active-green) !important;
    box-shadow: 0 0 8px 0 rgba(52, 77, 102, 0.08);
}

.primary-button:focus-visible {
    background-color: var(--hover-green) !important;
    border: solid 1px var(--dark-blue) !important;
    box-shadow: 0 0 16px 0 rgba(52, 77, 102, 0.16) !important;
}

.primary-button:disabled {
    background-color: var(--blue-10) !important;
    border-color: var(--blue-10) !important;
    color: var(--blue-60) !important;
}

/*Secondary button*/
.secondary-button {
    background-color: inherit !important;
    border: 1px solid var(--green) !important;
    color: var(--dark-blue) !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-stretch: normal !important;
    font-style: normal !important;
    font-weight: bold !important;
    letter-spacing: 1px !important;
    line-height: 1 !important;
    text-align: center !important;
    border-radius: 8px !important;
    height: 48px;
    padding: 17px 48px;
}

.secondary-button.small,
.primary-button.small,
.red-button.small {
    font-size: 12px !important;
    height: 32px !important;
    padding: 10px 16px !important;
    line-height: 0.5 !important;
}

.secondary-button:hover {
    background-color: var(--green-8) !important;
    box-shadow: 0 0 8px 0 rgba(52, 77, 102, 0.08);
}

.secondary-button:active {
    background-color: var(--green-16) !important;
    box-shadow: 0 0 8px 0 rgba(52, 77, 102, 0.08);
}

.secondary-button:focus-visible {
    background-color: var(--green-16) !important;
    box-shadow: 0 0 16px 0 rgba(52, 77, 102, 0.16) !important;
    border: solid 1px var(--dark-blue) !important;
}

.secondary-button:disabled {
    border: solid 1px var(--blue-20) !important;
    color: var(--blue-40) !important;
    box-shadow: none;
    background-color: var(--white) !important;
}

/*Red button*/
.red-button {
    color: var(--dark-blue) !important;
    background-color: inherit !important;
    border: solid 1px var(--soft-red) !important;
    border-radius: 8px !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1px !important;
    text-align: center;
    height: 48px;
    padding: 17px 48px;
}

.red-button:hover {
    background-color: rgba(238, 131, 115,0.08) !important;
    box-shadow: 0 0 8px 0 rgba(52, 77, 102, 0.08);
}

.red-button:active {
    background-color: rgba(238, 131, 115, 0.16) !important;
    box-shadow: 0 0 8px 0 rgba(52, 77, 102, 0.08);
}

.red-button:focus-visible {
    background-color: rgba(238, 131, 115, 0.16) !important;
    box-shadow: 0 0 16px 0 rgba(52, 77, 102, 0.16) !important;
    border: solid 1px var(--red) !important;
}

.red-button:disabled {
    box-shadow: none;
    opacity: 0.65;
    background-color: inherit !important;
}

/*Button style bg blue*/
/*Secondary*/
.secondary-button.blue-bg,
.dark .secondary-button.blue-bg {
    background-color: inherit !important;
    color: var(--white) !important;
}

.secondary-button.blue-bg:hover, .dark .secondary-button.blue-bg:hover {
    background-color: rgba(90, 190, 186, 0.08) !important;
}

.secondary-button.blue-bg:active, .dark .secondary-button.blue-bg:active {
    background-color: rgba(90, 190, 186, 0.16) !important;
}

.secondary-button.blue-bg:focus-visible, .dark .secondary-button.blue-bg:focus-visible {
    border: solid 1px var(--white) !important;
    box-shadow: 0 0 8px 0 rgba(52, 77, 102, 0.08) !important;
    background-color: rgba(90, 190, 186, 0.16) !important;
}

.secondary-button.blue-bg:disabled, .dark .secondary-button.blue-bg:disabled {
    border: solid 1px var(--blue-80) !important;
    color: var(--blue-60) !important;
    background-color: var(--dark-blue) !important;
}

/*Button style dark*/
/*Primary*/
.dark .primary-button:focus-visible {
    border: solid 1px var(--white);
}

.dark .primary-button:disabled {
    background-color: var(--blue-80);
    color: var(--blue-40);
}

/*Secondary*/
.dark .secondary-button {
    color: inherit !important;
}

.dark .secondary-button:hover {
    background-color: #242e32 !important;
}

.dark .secondary-button:active {
    background-color: #273C3E !important;
}

.dark .secondary-button:focus-visible {
    border: solid 1px var(--white) !important;
    background-color: #273C3E !important;
}

.dark .secondary-button:disabled {
    border: solid 1px var(--blue-80) !important;
    color: var(--blue-80) !important;
    background-color: var(--black) !important;
}

/*Red button*/
.dark .red-button {
    color: var(--white) !important;
}

.dark .red-button:hover {
    background-color: rgba(238, 131, 115, 0.16) !important;
}

.dark .red-button:active {
    background-color: rgba(238, 131, 115, 0.24) !important;
}

.red-button:focus-visible {
    background-color: rgba(238, 131, 115, 0.24) !important;
}

/*vecchio css*/
button:disabled,
.img-disabled {
    cursor: wait;
    pointer-events: all !important;
}

.img-disabled {
    opacity: 0.65;
}

.phone .circular-button {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center
}

.span-align-right {
    display: flex;
    flex-direction: row-reverse;
}

.span-j-space-b {
    display: flex;
    justify-content: space-between;
}

.display-align-center {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

/*Input, text area and select style*/
textarea,
input[type="text"],
input[type="email"],
input[type="password"],
select,
.custom-select-button,
.select-dropdown {
    border: solid 1px var(--blue-10) !important;
    background-color: var(--white);
    color: var(--dark-blue) !important;
    padding-left: 12px;
    border-radius: 4px !important;
    font-family: Roboto;
    font-size: 14px !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
select,
.custom-select-button {
    height: 48px;
    width: 100%;
}

input[type="text"]:hover,
input[type="text"]:focus,
input[type="email"]:hover,
input[type="email"]:focus,
input[type="password"]:hover,
input[type="password"]:focus,
textarea:hover,
textarea:focus,
select:hover,
select:focus,
.custom-select-button:hover,
.custom-select-button:focus,
.focus-custom-select {
    border: solid 1px var(--blue-20) !important;
    box-shadow: 0 0 8px 0 rgba(52, 77, 102, 0.08) !important;
}

input[type="text"]:focus-visible,
input[type="email"]:focus-visible,
input[type="password"]:focus-visible,
textarea:focus-visible,
textarea:focus,
select:focus-visible,
.custom-select-button:focus-visible {
    border: solid 1px var(--green) !important;
    box-shadow: 0 0 8px 0 rgba(52, 77, 102, 0.08) !important;
    outline: none !important;
}

textarea::placeholder,
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
select:has(option[value='']:checked) {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    color: var(--blue-60) !important;
}

input[type="text"]:not(:placeholder-shown),
input[type="email"]:not(:placeholder-shown),
input[type="password"]:not(:placeholder-shown),
textarea:not(:placeholder-shown),
textarea:not(:placeholder-shown),
.was-validated .form-select:valid,
.was-validated .custom-select-button,
.dark .was-validated .custom-select-button,
select:has(option[value='']:not(:checked)) {
    border: solid 1px var(--green) !important;
}

.was-validated input.form-control:invalid,
.was-validated .form-select:invalid {
    border: solid 1px var(--soft-red) !important;
}

.form-control.is-valid,
.was-validated .form-control:valid,
.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none !important;
}

.was-validated .form-select {
    --bs-form-select-bg-icon: none !important;
}

.form-select {
    --bs-form-select-bg-img: url('../Images/icons_18px_expand-more.svg') !important;
}

select option {
    color: var(--dark-blue) !important;
}

select,
.custom-select-button {
    font-weight: 500 !important;
    letter-spacing: 0.2px !important;
    color: var(--dark-blue);
    padding: 12px 16px;
}

select:disabled,
.custom-select-button:disabled {
    box-shadow: none !important;
    cursor: not-allowed;
}

.custom-select-button-loader {
    opacity: 0.65 !important;
    cursor: wait !important;
}

input[type='checkbox'],
select,
.custom-select-button {
    cursor: pointer;
}

.myCheckbox input {
    position: relative;
    z-index: 2;
    opacity: 0;
}

.myCheckbox>span {
    width: 18px;
    height: 18px;
    margin-left: -16px;
    margin-top: 2px;
    position: absolute;
    background: url("/src/Images/icons-18-px-checkbox.svg");
    background-repeat: no-repeat;
}

.myCheckbox input:checked+span {
    background: url("/src/Images/icons-18-px-checkbox-checked.svg");
}

/*Dark mode*/
.dark input[type="text"],
.dark input[type="email"],
.dark input[type="password"],
.dark textarea,
.dark select,
.dark .custom-select-button {
    background-color: var(--black) !important;
    border: solid 1px var(--black-84) !important;
    color: var(--white) !important;
}

.dark textarea::placeholder,
.dark input[type="text"]::placeholder,
.dark input[type="email"]::placeholder,
.dark input[type="password"]::placeholder,
.dark select::placeholder,
.dark .custom-select-button::placeholder,
.dark select:has(option[value='']:checked) {
    color: var(--blue-40) !important;
}

.dark input[type="text"]:hover,
.dark input[type="text"]:focus,
.dark input[type="email"]:hover,
.dark input[type="email"]:focus,
.dark input[type="password"]:hover,
.dark input[type="password"]:focus,
.dark textarea:focus,
.dark textarea:hover,
.dark select:focus,
.dark select:hover,
.dark .custom-select-button:focus,
.dark .custom-select-button:hover,
.dark .focus-custom-select {
    border: solid 1px var(--black-80) !important;
}

.dark input[type="text"]:focus-visible,
.dark input[type="email"]:focus-visible,
.dark input[type="password"]:focus-visible,
.dark textarea:focus-visible,
.dark textarea:focus-visible,
.dark select:focus-visible,
.dark .custom-select-button:focus-visible {
    border: solid 1px var(--green) !important;
    box-shadow: 0 4px 16px 0 rgba(29, 29, 29, 0.04) !important;
    outline: none !important;
}

.dark select option {
    color: var(--white) !important;
}

.dark .form-select {
    --bs-form-select-bg-img: url('../Images/expand-more-dark.svg') !important;
}

.dark .myCheckbox>span {
    background: url("/src/Images/icons-18-px-checkbox-dark.svg");
}

.dark .myCheckbox input:checked+span {
    background: url("/src/Images/icons-18-px-checkbox-checked-dark.svg");
}

.dark select:disabled:hover,
.dark .custom-select button:disabled {
    box-shadow: none !important;
}

/*link*/
.link-generic, .message-link {
    color: inherit !important;
    text-decoration: underline;
    cursor: pointer;
}

.message-link {
    font-weight: 600;
}

.link-green, .check span{
    color: var(--green);
    text-decoration: underline;
    cursor: pointer;
}

.link-error {
    color: var(--soft-red);
    text-decoration: underline;
    cursor: pointer;
}

/*Tab bootstrap*/
.nav-link {
    color: var(--green) !important;
}

.nav-tabs .nav-link.active {
    color: var(--dark-blue) !important;
}

.dark .nav-tabs .nav-link.active {
    color: var(--white) !important;
    background: var(--black-80) !important;
    border-bottom: 1px solid var(--black-80) !important;
}

/*Popover bootstrap*/
.popover-body {
    font-family: Roboto;
    color: var(--dark-blue) !important;
}

.dark#popover-basic {
    --bs-popover-arrow-border: var(--black) !important;
    --bs-popover-border-color: var(--black) !important
}

.dark .popover-body {
    color: var(--white) !important;
    background-color: var(--black-80);
    border-radius: 6.5px;
}

.dark .popover-arrow::after {
    border-top-color: var(--black-80) !important;
}

/*ScrollBar*/
::-webkit-scrollbar {
    width: auto;
}

::-webkit-scrollbar-track {
    background-color: var(--blue-5);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.045);
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--blue-20);
    border-radius: 8px;
}

.modal ::-webkit-scrollbar-track,
.modal ::-webkit-scrollbar-thumb {
    border-radius: 4px;
}

/*ScrollBar dark*/
.dark ::-webkit-scrollbar-track {
    background-color: var(--black-92);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.045);
    border-radius: 8px;
}

.dark ::-webkit-scrollbar-thumb {
    background-color: var(--black-84);
}

.dark ::-webkit-scrollbar-corner {
    background-color: var(--black-96);
}

/*Arrow animation*/
.rotate-arrow {
    transition: transform 0.4s;
    transform: rotate(-180deg);
}

.no-rotate-arrow {
    transition: transform 0.4s;
}

.dark .rotate-arrow {
    fill: var(--white);
}

.dark .no-rotate-arrow {
    fill: var(--white);
}

.hidden {
    display: none!important;
}

.no-pointer-events {
    pointer-events: none !important;
}