/* Issue banner css, it is used only if there is the issue banner into the DOM */
.issue-banner{
    font-size: 10px;
    min-height: 5vh;
    max-height: 5vh;
    background-color: var(--blue-10);
    margin: 0px -12px;
    padding: 0px 12px;
    display: flex;
    align-items: center;
}

.issue-banner.phone{
    font-size: 7px;
    min-height: 5vh;
    max-height: 5vh;
}

.pc .issue-banner.phone{
    font-size: 7px;
    min-height: 10vh;
    max-height: 10vh;
}

.issue-banner + div[name='messageContainer']{
    min-height: calc(80vh - 22px);
    max-height: calc(80vh - 22px);
}

.issue-banner.phone + div[name='messageContainer'].phone{
    max-height: calc(75vh - 22px) !important;
    min-height: calc(75vh - 22px) !important;
    max-height: calc(75dvh - 22px) !important;
    min-height: calc(75dvh - 22px) !important;
}

.issue-banner.phone + div[name='messageContainer'].pc {
    max-height: calc(70vh - 22px) !important;
    min-height: calc(70vh - 22px) !important;
    max-height: calc(70dvh - 22px) !important;
    min-height: calc(70dvh - 22px) !important;
}

.issue-banner.phone + div[name='messageContainer'].pc button[name='button-scrolldown']{
    bottom: calc(2*(7.5vh - 24px) + 77px);
}