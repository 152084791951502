/* Pages header */
.pages-header {
    height: 98px;
    border-bottom: solid 1px var(--blue-10);
    padding: 24px;
    margin: 0 !important;
    align-content: center;
    display: flex;
    align-items: center;
    color: var(--dark-blue);
}

.phone .pages-header {
    height: 58px;
    padding: 16px;
}

.pages-back-button {
    display: flex;
    cursor: pointer;
    padding: 0;
    align-items: center;
    width: fit-content !important;
    width: -moz-fit-content !important;
}

.pages-back-button svg {
    height: 20px !important;
    width: 20px !important;
    margin-right: 8px;
    transform: rotate(180deg);
}

.phone .pages-back-button svg {
    margin-right: 0;
}

.pages-back-button svg path {
    fill: var(--dark-blue);
}

.pages-header .title svg {
    height: 20px !important;
    width: 20px !important;
}

/* Pages body */
.pages-body {
    padding: 40px 60px;
    color: var(--dark-blue);
    overflow-y: auto;
    height: calc(100% - 98px);
}

.phone .pages-body {
    padding: 16px;
    height: calc(100% - 58px);
}

.pages-body .no-style-list {
    list-style: none;
    padding: 0;
}

.pages-body .li-with-icon{
    display: flex;
    align-items: center;
}

.pages-body .li-with-icon svg{
    margin-right: 8px;
}

.pages-body hr {
    border-top: solid 1px var(--blue-10);
    margin: 0;
    opacity: 1;
}

/* Dark mode */
/* Header */
.dark .pages-header {
    border-bottom: solid 1px var(--black-84);
}

.dark .pages-body hr {
    border-top: solid 1px var(--black-84);
}

.dark .pages-back-button svg path {
    fill: var(--white);
}

/* Body */
.dark .pages-body {
    color: var(--white);
}

/* Help center */
.help-center .pages-body{
    white-space: pre-line;
}

.help-center .li-with-icon{
    font-size: 18px;
    margin-left: -28px;
}

.help-center div:has(.li-with-icon:first-child){
    padding-left: 28px;
}

.help-center div:has(.li-with-icon:first-child) ul{
    padding-left: 16px;
}

.help-center .exampleList{
    display: block !important;
    list-style: disc;
    padding-left: 32px !important;
    margin-top: 8px !important;
}

.help-center .example{
    background-color: inherit !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    cursor: auto !important;
    padding-left: 0 !important;
    pointer-events: none !important;
}

.help-center .example img{
    display: none;
}