/* Side menù */
div[name='side-menu'] {
    background-color: var(--dark-blue);
    max-width: 18vw;
    min-width: fit-content;
    font-family: Roboto;
    max-height: 100vh;
    padding: 24px;
}

div[name='side-menu'].phone {
    max-width: none;
    min-width: 100%;
    padding: 16px !important;
}

div[name='side-menu'].phone.side-menu-close{
    height: 82px !important;
}

/* Side-menu header */
span[name='side-header'] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--blue-90);
}

div[name='side-menu'] div[name='logo'] {
    width: 162px;
    padding-bottom: 24px;
}

div[name='side-menu'].phone div[name='logo'] {
    padding-bottom: 0px !important;
}

div[name='side-menu'].phone.side-menu-exp span[name='side-header'] {
    padding-bottom: 16px;
}

div[name='side-menu'].phone.side-menu-close span[name='side-header'] {
    border: none !important;
}

.phone span[name='side-header'] {
    width: 100%;
}

.phone-button-header {
    border: none !important;
    background-color: var(--dark-blue) !important;
    padding: 0 !important;
}

/* Side-menu body */
div[name='side-menu-body'] {
    height: calc(100% - 74px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

span[name='upper-body'] {
    width: 100%;
}

.side-menu-buttons {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: bold !important;
    line-height: 1 !important;
    letter-spacing: 1px;
    width: 100%;
    background-color: var(--dark-blue) !important;
    border: none !important;
    border-bottom: solid 1px var(--blue-90) !important;
    border-radius: 0 !important;
    display: flex !important;
    align-items: center;
    padding: 16px 0 !important;
}

.side-menu-buttons:hover, .side-menu-buttons:active{
    background-color: var(--dark-blue) !important;
}

.side-menu-buttons:focus-visible, .side-menu-footer-buttons:focus-visible{
    background-color: rgba(90, 190, 186, 0.16) !important;
    border-radius: 8px !important;
    border: none !important;
    box-shadow: none !important;
}

.side-menu-buttons svg{
    margin-right: 10px;
    height: 20px;
    width: 20px;
}

.side-menu-buttons svg path{
    fill: var(--blue-60) !important;
}

.side-menu-buttons .help-icon path:first-child, .help-icon path:first-child{
    fill: none !important;
}

.side-menu-buttons:hover svg path, .side-menu-buttons:active svg path, .side-menu-buttons.active svg path{
    fill: var(--green) !important;
}

.side-menu-footer-buttons {
    font-size: 12px !important;
    background-color: var(--dark-blue) !important;
    border-color: var(--dark-blue) !important;
}

/*Switch*/
/* manca l'animazione sul focus e sul focus visible */
span[name='theme-switch'] {
    display: flex;
    width: 55px !important;
    height: 26px !important;
    justify-content: space-between !important;
    cursor: pointer;
    background-color: var(--white);
    border-radius: 25px;
    align-items: center;
}

span[name='theme-switch'] div {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .15s ease-in-out;
}

span[name='theme-switch'] div:first-child {
    margin-left: 5px;
    background-color: var(--green);
    border-radius: 25px;
}

span[name='theme-switch'] div:last-child {
    margin-right: 5px;
    border-radius: 25px;
}

/*Dark switch*/
.dark span[name='theme-switch'] {
    background-color: var(--blue-90) !important;
}

.dark span[name='theme-switch'] div:first-child {
    background-color: unset !important;
}

.dark span[name='theme-switch'] div:last-child {
    background-color: var(--green);
}

.dark span[name='theme-switch'] div:last-child path {
    fill: var(--white) !important;
}

.dark span[name='theme-switch'] div:last-child g {
    opacity: 1 !important;
}