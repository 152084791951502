.loadingPage{
    background-color: var(--dark-blue);
    font-family: Roboto;
    font-size: 16px;
    color: var(--white);
    padding: 32px;
    display: grid !important;
    justify-content: center;
    align-content: space-between;
    justify-items: center;
    text-align: center;
    font-weight: normal;
    height: 100vh;
    height: 100dvh;
    z-index: 10;
    transition: all 0.5s;
}

.loadingPage.collapse, .loadingPage.collapse img, .loadingPage.collapse div, .loadingPage.collapse span{
    visibility: collapse;
    height: 0px !important;
    padding: 0;
    border: 0;
}

.loading-robot-icon{
    border: solid 40px var(--blue-90);
    background-color: var(--blue-90);
    border-radius: 40px;
}