.custom-select {
	position: relative;
	/* width: 400px; */
	max-width: 100%;
}

.custom-select-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 6px;
}

.custom-select-button:disabled {
	opacity: 1;
}

.selected-value {
	text-align: left;
	align-items: center;
	display: flex;
}

/* List box */
.select-dropdown {
	position: absolute;
	width: 100%;
	box-shadow: 0 0 8px 0 rgba(52, 77, 102, 0.08);
	background-color: var(--white);
	border-radius: 0 0 4px 4px !important;
	padding: 10px;
	max-height: 200px;
	font-weight: 500;
	overflow-y: auto;
	transition: 0.5s ease;
	z-index: 1;
	border: 1px solid var(--blue-20) !important;
	border-top: none !important;
}

.dark .select-dropdown {
	border: solid 1px var(--black-80) !important;
	border-top: none !important;
	background-color: var(--black);
	box-shadow: 0 1px 3px rgba(23, 23, 23, 0.24) !important;
	color: var(--white) !important;
}

.custom-select .custom-option {
	cursor: pointer;
	margin: 0;
	padding: 8px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	color: var(--blue-60);
}

.custom-select .custom-option:focus,
.custom-select .custom-option:focus-visible {
	background-color: var(--blue-5) !important;
	color: var(--dark-blue);
	outline: none !important;
}

.focus-custom-select {
	border-radius: 4px 4px 0 0 !important;
}

.dark .custom-select .custom-option:focus,
.dark .custom-select .custom-option:focus-visible {
	background: var(--black-84) !important;
	color: var(--white);
}

/* Flag */
span[name='flag-icon'] {
	border-radius: 2px;
}

.custom-select span[name='flag-icon'] {
	margin-right: 8px;
}

/*Phone Sign up*/
.row .custom-select {
	padding: 0px;
}