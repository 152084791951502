#privacy-policy-modal .row div{
    margin-bottom: 16px;
}

svg[name='privacy-svg']{
    margin: 0 2px 0 -3px;
}

.phone svg[name='privacy-svg']{
    margin-right: -2px !important;
}

/*Privacy policy*/
table[name='privacy-table'] {
    border: 1px solid var(--blue-10);
    border-radius: 10px !important;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 16px;
}

table[name='privacy-table'] th,
table[name='privacy-table'] td {
    border-right: 1px solid var(--blue-10);
    border-bottom: 1px solid var(--blue-10);
    padding: 6px;
}

table[name='privacy-table'] th:last-child,
table[name='privacy-table'] td:last-child {
    border-right: none;
}

table[name='privacy-table'] tbody tr:last-child th,
table[name='privacy-table'] tbody tr:last-child td {
    border-bottom: none;
}