.rating-icon{
    width: 24px;
    height: 24px;
    padding-right: 6px;
    cursor: pointer;
    background-color: var(--dark-blue);
}

.rating-icon.empty,
.disable-stars .rating-icon.empty:hover, .disable-stars .rating-icon.empty:has(~ .rating-icon.empty:hover){
    -webkit-mask-image: url('../../../Images/star_empty.svg');
    mask-image: url('../../../Images/star_empty.svg');
}

.rating-icon.empty:has(~ .rating-icon.empty:hover), .rating-icon.empty:hover, 
.rating-icon.full{
    -webkit-mask-image: url('../../../Images/star_fill.svg');
    mask-image: url('../../../Images/star_fill.svg');
}

.disable-stars .rating-icon{
    cursor: wait;
    opacity: 0.65;
}


p[name='feed-title']{
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 8px;
    padding: 16px 16px 0 16px;
}

p[name='feed-title'] + span{
    padding-bottom: 16px;
}

.p-label-new{
    font-size: 16px;
    font-family: Roboto;
    font-weight: 400;
    margin-bottom: 0px;
}

div[name='modal-star']{
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
}

div[name='modal-star'] > span{
    display: flex;
    padding: 8px;
    border-radius: 4px;
    background-color: var(--blue-10);
}

/*Dark mode*/
.dark .rating-icon{
    background-color: var(--green);
}

.dark div[name='modal-star'] > span{
    background-color: var(--black-88);
}