@keyframes fade_in_out {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.25;
    }

    100% {
        opacity: 1;
    }
}

.bubble {
    display: flex;
    width: 100%;
    padding: 16px 16px 0 0;
    align-items: center;
    justify-content: flex-end;
}

.bubble.translating-loader{
    justify-content: center;
}

.bubbleDot {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: var(--green);
    animation: fade_in_out 1.5s infinite;
    animation-delay: 0.25s;
}

.bubbleDot:first-of-type {
    margin: 0 8px;
    animation-delay: 0s;
}

.bubbleDot:last-of-type {
    margin-left: 8px;
    animation-delay: 0.5s;
}