/* Modal structure */
.modal {
    color: var(--dark-blue);
    font-family: Roboto;
    height: 95vh;
    min-height: fit-content;
}

.modal-content {
    border-radius: 4px !important;
    box-shadow: 0 4px 16px 0 rgba(29, 29, 29, 0.04);
}

.modal-header {
    height: 80px;
    border-bottom: solid 1px var(--blue-10) !important;
}

.modal-body {
    overflow: auto;
    max-height: calc(100vh - 81px - 4rem) !important;
    padding: 24px !important;
}

/*custom modal*/
.ul-custom {
    margin-bottom: 0px;
}

.modal-dim {
    max-width: 50% !important;
    max-height: 10% !important;
}

custom-li::marker {
    font-weight: bold;
    font-size: 1.10em !important;
}

custom-li>b {
    font-size: 1.10em !important;
}

.modal-46w {
    min-width: 46% !important;
    /* max-height: 50% !important;  */
}

/* Modal header */
.title {
    height: 26px;
    font-family: Roboto;
    font-size: 20px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: var(--dark-blue);
    display: flex;
    align-items: center;
}

.dark .title{
    color: var(--white);
}

#close {
    padding: 10px;
}

#close svg path {
    fill: var(--dark-blue);
}

.close-button {
    border-radius: 25px !important;
    width: 48px;
    height: 48px;
    border: solid 2px var(--blue-10) !important;
    background-color: var(--white) !important;
}

.close-button:hover {
    background-color: var(--blue-10) !important;
}

.close-button:active {
    background-color: var(--blue-20) !important;
    border: solid 2px var(--blue-20) !important;
}

.close-button:focus-visible{
    background-color: var(--blue-10) !important;
    border: solid 2px var(--blue-40) !important;
    box-shadow: 0 0 16px 0 rgba(52, 77, 102, 0.16) !important;
}

.modal-icon {
    margin-right: 8px;
    color: var(--green);
}

.modal-icon path{
    fill: var(--green)
}

/* Modal body */
.input-row {
    margin-bottom: 24px !important;
}

/*Link and Form.Label*/
label, .text-label-style{
    font-family: Roboto Mono;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
}

label {
    margin-bottom: 8px;
    padding-left: 0px !important;
}

.dark label{
    color: var(--blue-20);
}

.text-label-style{
    color: var(--blue-60);
}

.form-label {
    width: 100%;
    text-align: center;
}

.lab {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
}

.check {
    text-align: center;
    margin-left: 5px;
    font-family: Roboto;
}

.dark .check{
    color: var(--white) !important;
}

/*Tooltip icon and text*/
.label-with-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    padding-left: 0 !important;
}

.label-with-info label {
    margin: 0px !important;
}

.label-with-info svg {
    cursor: pointer;
}

.tool {
    text-align: left;
    color: var(--white);
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    background-color: var(--dark-blue);
    margin: 0;
}

.tool-no-wrap {
    white-space: nowrap;
}

#tooltip {
    --bs-tooltip-bg: var(--dark-blue);
    position: fixed;
    margin-bottom: 5px;
    inset: 5px auto auto 0px !important;
}

.tool-no-wrap .tooltip-inner {
    max-width: fit-content;
}

.tooltip.show {
    opacity: 1 !important;
}

#tooltip .tooltip-arrow {
    left: 1px !important;
}

.tool-icon {
    cursor: pointer;
}

.tool-icon path:last-child {
    fill: var(--blue-60);
}

.dark .tool-icon path:last-child {
    fill: var(--blue-80) !important;
}

/*Login*/
div[name='login-message'] {
    margin-bottom: 16px;
    text-align: center;
}

/*Login transition*/
span[name^='resetPass'] {
    padding: 0;
    text-align: left;
    overflow: hidden;
}

span[name^='resetPass'] button {
    width: 100%;
}

span[name='resetPass-compact'] {
    visibility: collapse;
    transition: height 0.4s;
    height: 0 !important;
}

span[name='resetPass-exp'] {
    height: 166px;
    visibility: visible;
    transition: height 0.4s;
    margin-top: 24px;
    padding-top: 16px;
    border-top: solid 1px var(--blue-10);
}

.dark span[name='resetPass-exp'] {
    border-top: solid 1px var(--black-84);
}

/*Password*/
.col-eye {
    height: 0px;
    display: block;
}

.col-eye svg {
    margin-top: -70px !important;
    cursor: pointer;
    margin-left: 90%;
    margin-right: 0px !important;
    fill: var(--blue-40);
}

.dark .col-eye svg {
    fill: var(--blue-80);
}

/*Errors*/
.errorMessage {
    color: var(--soft-red);
    text-align: center;
    font-family: Roboto;
    font-size: 15px;
}

.invalid,
.invalid-password {
    font-family: Roboto;
    color: var(--soft-red) !important;
    font-size: .875em;
    padding-left: 0px !important;
}

.invalid-password {
    margin-top: 4px !important;
}

/*Propmt modal*/
p[name='skip'] {
    font-size: 12px;
    width: fit-content;
    /* text-align: end; */
    padding: 0;
    margin: 24px 12px 0 12px;
}

p[name='skip']:hover {
    text-decoration: underline !important;
    cursor: default;
}

/*Dark mode*/
.dark.modal {
    color: var(--white);
    font-family: Roboto;
}

.dark .modal-content{
    background-color: var(--black-96);
    border: solid 1px var(--black-84) !important;
}

.dark.modal .modal-body {
    background-color: var(--black-96);
    border-radius: 0 0 4px 4px;
}

.dark.modal .modal-header {
    background-color: var(--black-96);
    border-radius: 4px 4px 0 0;
    border-bottom: solid 1px var(--black-84) !important;
}

.dark.modal #close svg path {
    fill: var(--white);
}

.dark.modal .close-button{
    background-color: var(--black-96) !important;
    border: solid 2px var(--black-84) !important;
}

.dark.modal .close-button:hover{
    background-color: var(--black-84) !important;
}

.dark.modal .close-button:active{
    background-color: var(--black-80) !important;
    border: solid 2px var(--black-80) !important;
}

.dark.modal .close-button:focus-visible{
    background-color: var(--black-80) !important;
    border: solid 2px var(--white) !important;
}