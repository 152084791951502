.profile-tabs-cntnr {
    padding-top: 0;
}
.profile-tabs-cntnr .nav.nav-tabs {
    justify-content: center;
}

.profile-tabs-cntnr li.nav-item .nav-link:not(.active) {
    font-weight: 600;
    padding: 16px 12px;
    border: none;
    color: var(--blue-50)!important;
}

.profile-tabs-cntnr li.nav-item .nav-link.active {
    font-weight: 600;
    padding: 16px 12px;
    color: var(--dark-blue)!important;
    background-color: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 3px solid!important;
    border-color: var(--green)!important;
}

#my-profile-tabs-tab-profile {
    padding: 16px 28px;
}


.profile-label {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1;
    color: var(--dark-blue);
    text-align: start;
    margin-bottom: 16px;
}

.password-label {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete-account {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.phone .delete-account{
    display: block;
}

.delete-account p {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.5px;
    color: var(--blue-60);
}

.phone .delete-account p {
    margin-bottom: 16px !important;
}

/* Error and success message */
.profile-message{
    background-color: var(--white);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 16px;
    font-family: Roboto;
    color: var(--dark-blue);
}

.profile-message:has(.successMessage){
    border: solid 1px var(--green);
}

.profile-message:has(.errorMessage){
    border: solid 1px var(--soft-red);
}

.profile-message .errorMessage{
    color: var(--dark-blue)
}

.profile-message svg{
    margin-right: 8px;
}

.iconSuccess {
    color: var(--green);
}

/*Section transition*/
.profile-compact {
    visibility: collapse;
    transition: all 0.4s;
    max-height: 0 !important;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.profile-exp {
    max-height: 1000px;
    visibility: visible;
    transition: all 0.4s;
    overflow: hidden;
}

/*Dark mode*/
.dark .profile-label {
    color: var(--white);
}

.dark .profile-label svg {
    fill: var(--white);
}

.dark .delete-account p {
    color: var(--blue-40);
}

.dark .profile-message{
    background-color: var(--black-88);
}

.dark .profile-message, .dark .profile-message .errorMessage{
    color: var(--white);
}

.dark .profile-tabs-cntnr li.nav-item .nav-link.active {
    color: var(--white)!important;
}