/* Send message container*/
div[name='sendMessageContainer'] {
    padding: calc(7.5vh - 45px) 0;
    margin: 0 24px;
}

.phone div[name='sendMessageContainer']{
    padding: calc(6vh - 32px) 0;
    padding: calc(6dvh - 32px) 0;
    align-items: center;
    margin: 0;
}

div[name='sendMessageInput'] {
    display: flex;
    box-shadow: 0 0 8px 0 rgba(52, 77, 102, 0.08);
    background-color: var(--white);
    border-radius: 8px;
    height: 64px;
    border: solid 1px var(--white) !important;
}

div[name='sendMessageInput']:hover,
div[name='sendMessageInput']:has(input[type='text'].input-message:focus) {
    box-shadow: 0 0 8px 0 rgba(52, 77, 102, 0.08);
    border: solid 1px var(--blue-20) !important;
}

input[type='text'].input-message {
    border: none !important;
    height: 58px;
    font-size: 18px !important;
    margin-top: 2px;
}

input[type='text'].input-message:hover,
input[type='text'].input-message:focus {
    box-shadow: none !important;
}

div[name='footer-info'] {
    font-family: Roboto;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: center;
    color: var(--dark-blue);
    margin-top: 8px;
}

.phone div[name='footer-info'] {
    display: none;
}

/*Send button*/
div[name='send-button-div'] {
    display: flex;
    align-items: center;
    margin-right: 16px;
}

div[name='send-button-div'] span {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1px;
    margin-right: 8px;
    font-family: Roboto;
    color: var(--blue-40);
}

div[name='send-button-div'] span:has(+button[name='send-button']:hover),
div[name='send-button-div'] span:has(+button[name='send-button']:focus-visible) {
    color: var(--dark-blue);
}

button[name='send-button'] {
    height: 40px !important;
    width: 40px !important;
    padding: 0;
    border-radius: 25px;
    border-color: var(--dark-blue) !important;
    background-color: var(--dark-blue) !important;
}

button[name='send-button'] path {
    fill: var(--white)
}

button[name='send-button']:hover path,
button[name='send-button']:focus-visible path {
    fill: var(--green);
}

button[name='send-button']:active {
    border-color: var(--blue-80) !important;
}

button[name='send-button']:active,
button[name='send-button']:focus-visible {
    background-color: var(--blue-80) !important;
    box-shadow: none;
}

/*Dark mode*/
/*send message container*/
.dark div[name='sendMessageInput'] {
    background-color: var(--black-96);
    border: solid 1px var(--black-96) !important;

}

.dark div[name='sendMessageInput']:hover,
.dark div[name='sendMessageInput']:has(input[type='text'].input-message:focus) {
    border: solid 1px var(--blue-90) !important;
}

.dark input[type='text'].input-message {
    border: none !important;
    background-color: var(--black-96) !important;
}

.dark div[name='footer-info'] {
    color: var(--blue-40);
}

/*Send button*/
.dark div[name='send-button-div'] span {
    color: var(--blue-80);
}

.dark div[name='send-button-div'] span:has(+button[name='send-button']:hover),
.dark div[name='send-button-div'] span:has(+button[name='send-button']:focus-visible) {
    color: var(--green);
}

.dark button[name='send-button']:active {
    border-color: var(--blue-90) !important;
}

.dark button[name='send-button']:active,
.dark button[name='send-button']:focus-visible {
    background-color: var(--blue-90) !important;
    box-shadow: none;
}

.dark button[name='send-button']:focus-visible {
    border-color: var(--blue-60);
}