/* Message container */
div[name='messageContainer'] {
    box-shadow: 0 0 8px 0 rgba(52, 77, 102, 0.08);
    border-radius: 12px;
    min-height: calc(85vh - 48px);
    max-height: calc(85vh - 48px);
    margin: 24px;
    overflow-y: auto;
    font-family: Roboto;
    color: var(--dark-blue);
    background-color: var(--white);
}

div[name='messageContainer'].phone {
    max-height: calc(88vh - 92px) !important;
    min-height: calc(88vh - 92px) !important;
    /*dvh is supported only by new browser*/
    max-height: calc(88dvh - 92px) !important;
    min-height: calc(88dvh - 92px) !important;
    margin: 10px 0px 0px 0px;
}

span[name='clear-span'] {
    margin: 16px;
}

/* Message */
.message {
    display: flex;
    padding: 16px 16px 0px 16px;
}

.message span[name='message-text'] {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
}

.message span[name='message-text'].varchat {
    background: var(--green-8);
}

.message span[name='message-text'].you {
    background: var(--blue-5);
}

.icon {
    height: 50px;
    margin-right: 16px;
}

p[name='name'] {
    font-size: 18px;
    margin-bottom: 3px;
    font-weight: bold;
    padding-top: 2px;
    margin-right: 10px;
}

p[name='time'] {
    font-size: 12px;
    font-weight: 500;
    color: var(--blue-60) !important;
}

span[name='message-header'] {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.phone span[name='message-header'] {
    align-items: flex-start;
}

span[name='message-header'] span {
    display: flex;
    align-items: baseline;
}

.phone span[name='message-header']>span{
    align-items: flex-start;
}

span[name='message-header'] p {
    margin-bottom: 0;
    font-family: Roboto;
    color: var(--dark-blue);
}

.copy-icon {
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 25px;
    background-color: var(--white);
}

.copy-icon:has(+ *){
    margin-right: 8px;
}

.copy-icon img{
    filter: invert(26%) sepia(18%) saturate(1236%) hue-rotate(169deg) brightness(94%) contrast(85%);color: var(--dark-blue);
}

.tooltip-copy {
    position: fixed;
    background-color: rgb(52, 77, 102);
    padding: 0.25rem 0.5rem;
    color: white;
    border-radius: 0.375rem;
    font-family: Roboto;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
    pointer-events: none;
}

.tooltip-copy:after {
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    border-style: solid;
    border-width: 8px;
    border-color: rgba(52, 77, 102) transparent transparent transparent;
    left: calc(50% - 8px);
}

.message-section{
    background-color: var(--green-16);
    border-radius: 4px;
    margin-top: 16px;
    font-size: 14px;
}

.message-section .section-exp{
    padding: 0 16px 16px 16px;
}

.message-section .section-compact{
    padding: 0 16px;
}

div[name='ref-title'] {
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 16px;
}

div[name='ref-title'] span{
    display: flex;
}

div[name='ref-title'] p {
    margin-bottom: 0px;
}

div[name='ref-title'] span p:nth-child(2) {
    color: var(--green);
}

.ref-col {
    padding-bottom: 16px;
}

tr:last-child .ref-col{
    padding-bottom: 0;
}

table[name='clinvar-table'] {
    width: 100%;
}

table[name='clinvar-table'] tr:first-child td{
    padding-top: 0;
}

table[name='clinvar-table'] tr:last-child{
    border: none;
}

.rcv-ref-col {
    padding: 16px 24px 16px 0;
    vertical-align: top;
}

tr[name='row-no-border'] .rcv-ref-col, tr[name='row-with-border']:last-child .rcv-ref-col{
    padding-bottom: 0;
}

tr[name='row-with-border']{
    border-bottom: 1px solid var(--blue-20);
}

.clinvar-stars-check{
    background-color: var(--green);
    cursor: auto;
}

button[name='button-scrolldown'] {
    right: 64px;
    bottom: calc(2*(7.5vh - 24px) + 80px);
    position: absolute;
    display: block;
    padding: 8px 16px !important;
    z-index: 1;
}

button[name='button-scrolldown'] svg{
    height: 16px;
    width: 16px;
    transform: rotate(90deg);
}

.phone button[name='button-scrolldown'] {
    right: 32px;
    bottom: calc(2 * (5vh - 24px) + 60px);
    bottom: calc(2 * (5dvh - 24px) + 70px);
}

.ok, .errorValidation{
    margin-bottom: 0 !important;
}

.error,
.warning {
    margin-bottom: 16px !important;
    display: flex;
    background-color: var(--white);
    padding: 16px !important;
    border-radius: 4px;
}

.error svg,
.warning svg {
    margin-right: 8px;
}

.warning path {
    fill: var(--yellow) !important;
}

.error path{
    fill: var(--soft-red) !important;
}

/*Multilanguage*/
svg[name='translate-svg'] {
    margin-bottom: 5px;
    width: 24px;
    margin-left: 2px;
    fill: var(--green);
}

button svg[name='translate-svg']{
    width: 16px;
    margin: -2px 0 0 0;
}

/*Example*/
.pc .exampleList{
    display: flex;
}

.phome .exampleList{
    display: block;
}

.exampleList{
    list-style: none;
    flex-wrap: wrap;
    padding-left: 0;
}

.example {
    width: fit-content;
    color: inherit;
    cursor: pointer;
    background-color: var(--green-16);
    border-radius: 8px;
    padding: 7px 16px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
}

.phone .example{
    max-width: 290px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.readMore {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold !important;
    cursor: pointer;
    margin-bottom: 0;
    color: var(--blue-60);
    font-weight: 500;
    display: flex;
    align-items: center;
}

.readMore p{
    margin-bottom: 0;
}

.section-exp {
    visibility: visible;
    transition: all 0.4s;
    max-height: 5000px;
    overflow: hidden;
}

.section-compact {
    visibility: collapse;
    transition: all 0.4s;
    max-height: 0 !important;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

/*Dark mode*/
/* da finire da controllare su zeplin per vedere boxshadow ecc */
.dark div[name='messageContainer'] { 
    background: var(--black-96);
    color: var(--white);
    border: none;
}

.dark p[name='time'] {
    color: var(--blue-50) !important;
}

.dark .message span[name='message-text'].varchat {
    background: var(--black-92);
}

.dark .message span[name='message-text'].you , .dark .example{
    background: var(--black-88);
}

.dark .readMore{
    color: var(--blue-50);
}

.dark .copy-icon{
    background-color: var(--black-88);
}

.dark .you .copy-icon{
    background-color: var(--black-84);
}

.dark .copy-icon img{
    filter: invert(100%) sepia(1%) saturate(0%) hue-rotate(161deg) brightness(104%) contrast(101%);
}

.dark [name='message-header'] p {
    color: var(--white);
}

.dark .warning path:nth-child(2) {
    fill: var(--white);
}

.dark tr[name='row-with-border']{
    border-bottom: 1px solid var(--black-80);
}

.dark .message-section, .dark .error, .dark .warning {
    background-color: var(--black-88);
}