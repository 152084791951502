.profile-tabs-cntnr .tab-content {
    max-height: 75%;
    overflow-y: auto;
    overflow-x: hidden;
}

.history-row {
    width: 60%;
    margin: 24px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.history-row-arrow {
    width: 22px;
    height: 22px;
    margin: 24px 0;
    margin-bottom: 0;
}
